import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import first from 'lodash/first';
import get from 'lodash/get';
import Head from 'next/head';
import Link from 'next/link';

import { NEXT_PUBLIC_CDN_ROOT } from '../../../../cfg';
import Button from '../../../../controls/Button';
import Markdown from '../../../../controls/Markdown';
import Picture from '../../../../controls/Picture';
import { sendSelectPromotionEvent, sendViewPromotionEvent } from '../../../../helpers/analytics';
import getDefaultWidth from '../../../../helpers/getDefaultWidth';
import useCookie from '../../../../hooks/useCookie';
import useWindowResize from '../../../../hooks/useWindowResize';
import arrowDownIcon from '../../../../icons/ArrowDownIcon';
import arrowRightIcon from '../../../../icons/ArrowRightIcon';
import Carousel from '../../../Carousel';
import MiniReview from '../../../MiniReview';
import MobileFeaturesBanner from '../../../MobileFeaturesBanner';
import Typography from '../../../Typography';
import CollectionList from '../CollectionList';

import arrowLinkIcon from './assets/arrow-link.svg';
import MainFilters from './Filters';

import styles from './Main.module.css';

import checkArrowIcon from '/assets/imgs/main/check-arrow.svg';
import clockIcon from '/assets/imgs/main/clock.svg';
import linkArrowIcon from '/assets/imgs/main/link-arrow.svg';
import phoneIcon from '/assets/imgs/main/phone.svg';
import pointIcon from '/assets/imgs/main/point.svg';
import SvgIcon from '/components/SvgIcon';

const trophyIcon = '/static/imgs/main/trophy.jpg';
const colorBanner = '/static/imgs/mainPage/colorBanner.jpg';

const Main = ({ info, filters, host, isPhone, isTablet }) => {
  const [readMore, setReadMore] = useState(false);
  const [isServicesExpanded, setIsServicesExpanded] = useState(false);

  const handleServicesExpand = () => {
    setIsServicesExpanded(!isServicesExpanded);
  }

  const defaultWidth = getDefaultWidth({ isPhone, isTablet });

  const windowSize = useWindowResize();

  const windowWidth = windowSize?.width || defaultWidth;

  let width = 1538;
  let slideWidth = width - 370;
  let slideHeight = null;
  let padding = 0;

  if (windowWidth < 768) {
    padding = 15;
    width = windowWidth - padding * 2;
    slideWidth = windowWidth;
    slideHeight = Math.floor(slideWidth * 3 / 4);
  } else if (windowWidth < 1280) {
    padding = 40;
    width = windowWidth - padding * 2;
    slideWidth = width;
    slideHeight = Math.floor(slideWidth * 3 / 4);
  } else if (windowWidth < 1440) {
    padding = 48;
    width = windowWidth - padding * 2;
    slideWidth = width - 370;
  } else if (windowWidth < 1920) {
    padding = 75;
    width = (windowWidth > 1538 + padding * 2 ? 1538 - padding * 2 : windowWidth) - padding * 2;
    slideWidth = width - 370;
  }

  const handleBannerObserver = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.target.classList.contains('banner-item')) {
        sendViewPromotionEvent({ element: entry.target });

        observer.unobserve(entry.target);
      }
    });
  };

  const [gaCookie] = useCookie('_ga');

  useEffect(() => {
    global.window?.FS?.identify(gaCookie);

    const newBannerObserver = new IntersectionObserver(handleBannerObserver, {
      root: document.querySelector('.banner-items'),
      rootMargin: '0px',
      threshold: 0.5
    });

    const bannerItems = document.querySelectorAll('.banner-item');

    bannerItems.forEach((item) => {
      newBannerObserver.observe(item);
    });

    return () => {
      newBannerObserver.disconnect();
    };
  }, []);

  const handlerEvent = ({ label, action, category = 'Главная страница' }) => {
    window.dataLayer?.push({
      event: 'autoEvent',
      eventCategory: category,
      eventAction: action,
      eventLabel: label
    });
  };

  const slider = info?.slider;
  const services = info?.services;
  const banners = info?.banners;
  const bannersKover = banners?.kover;
  const bannersLiberty = banners?.liberty;
  const advantages = info?.advantages;
  const shops = info?.shops;
  const projects = info?.projects;
  const seoText = info?.seoText;
  const reviews = info?.reviews;
  const filtersBlock = info?.filtersBlock;
  const designersInvite = info?.invite;
  const bestArticles = info?.bestArticles || [];
  const [bestArticlesFirst, ...bestArticlesLast] = bestArticles;

  const renderedLibertySquareAction = (
    (
      <Link
        href='/catalog/f/category-all-furniture'
        className={styles.mainSquareAction}
        style={{ backgroundImage: `url(${colorBanner})` }}
        onClick={() => handlerEvent({ action: 'Каталог мебели', label: 'Каталог мебели' })}>

        <div className={styles.mainSquareActionContent}>
          <Typography className={styles.mainSquareActionTitle} variant='h3' component='span'>
            Каталог мебели
          </Typography>
          <Typography className={styles.mainSquareActionButton} variant='button' bold>
            Смотреть
            <SvgIcon svg={linkArrowIcon} />
          </Typography>
        </div>
      </Link>
    )
  );

  const carpetSquareStyle = {
    backgroundImage: `url(${colorBanner})`,
    margin: 0
  };

  if (windowWidth < 1024) {
    carpetSquareStyle.width = 'calc(50% - 10px)';
  }

  const makeHandleFeatureBannerClick = name => () => {
    window.dataLayer?.push({
      event: 'promoClick',
      eventCategory: 'Главная',
      eventAction: 'Баннеры',
      eventLabel: name, // текст баннера
      ecommerce: {
        promoClick: {
          promotions: [{
            name, // н-р Ковры в загородный дом
            position: 'Главная. Баннеры в середине'
          }]
        }
      }
    });
  };

  const renderSlide = (slide, index) => {
    if (!slide) {
      return null;
    }

    const slideId = slide?.id;
    const slideButtonText = slide?.buttonText || null;
    const slideLink = slide?.link || null;
    const slideName = slide?.name || null;
    const slidePicture = slide?.picture || null;
    const slideRegion = slide?.region || null;
    const slideSliderText = slide?.sliderText || null;
    const slideLabelText = slide?.labelText || null;


    const handleSliderBannerClick = () => {
      global.window.dataLayer?.push({
        event: 'promoClick',
        eventCategory: 'Главная',
        eventAction: 'Верхний слайдер',
        eventLabel: slideSliderText, // текст баннера
        ecommerce: {
          promoClick: {
            promotions: [{
              id: slideId, // если есть, можно не передавать
              name: slideName, // н-р Ковры в загородный дом
              position: 'Главная. Верхний слайдер'
            }]
          }
        }
      });

      sendSelectPromotionEvent({
        item: {
          id: slideId,
          name: slideName,
          href: slideLink,
          index
        }
      });
    };

    const content = (
      <>
        {slidePicture && (
          <Picture
            picture={slidePicture}
            imgStyle={{ minHeight: slideHeight }}
            imgAttributes={{ loading: index > 0 ? 'lazy' : undefined, width: 360, height: 270 }}
          />
        )}

        {(!!slideLabelText || !!slideSliderText) && (
          <div className={styles.mainFeaturesSliderItemInfo}>
            {slideLabelText && <Typography variant='body1' component='span'
                                           className={styles.mainFeaturesSliderItemLabel}>{slideLabelText}</Typography>}
            {slideSliderText && <Markdown className={styles.mainFeaturesSliderItemTitle} source={slideSliderText} />}
          </div>
        )}

        {slideButtonText && (
          <Button className={styles.mainFeaturesSliderItemButton}>
            {slideButtonText}
            <SvgIcon svg={linkArrowIcon} />
          </Button>
        )}
      </>
    );

    if (slideLink) {
      return (
        (<Link
          href={slideLink || ''}
          key={`slide-${slideId}`}
          prefetch={false}
          className={classnames(styles.mainFeaturesSliderItem, 'banner-item')}
          onClick={handleSliderBannerClick}
          style={{ minHeight: slideHeight }}
          data-banner-id={slideId}
          data-banner-name={slideName}
          data-banner-href={slideLink}
          data-banner-index={index + 1}>

          {content}

        </Link>)
      );
    }
    return (
      <div
        className={classnames(styles.mainFeaturesSliderItem, 'banner-item')}
        style={{ minHeight: slideHeight }}
        onClick={handleSliderBannerClick}
        data-banner-id={slideId}
        data-banner-name={slideName}
        data-banner-href={slideLink}
        data-banner-index={index + 1}
      >
        {content}
      </div>
    );
  };

  const handleQuickLinkClick = (e) => {
    window.dataLayer?.push({
      event: 'autoEvent',
      eventCategory: 'Главная моб',
      eventAction: 'Быстрые категории',
      eventLabel: e.target.innerText
    });
  };

  return (
    <>
      <Head>
        <title key='title'>{get(info, 'title', '')}</title>
        <meta name='description' content={get(info, 'description', '')} key='description' />
        <meta property='og:title' content={get(info, 'title', '')} key='og-title' />
        <meta property='og:description' content={get(info, 'description', '')} key='og-description' />
        <meta property='og:site_name' content='Интернет-магазин Kover.ru' key='og-site-name' />
        <meta property='og:image' content='/static/imgs/meta-image.jpg' key='og-image' />
        <meta property='og:type' content='website' key='og-type' />
        <meta name='twitter:title' content={get(info, 'title', '')} key='twitter-title' />
        <meta name='twitter:description' content={get(info, 'description', '')} key='twitter-description' />
        <meta name='twitter:card' content='summary_large_image' key='twitter-card' />
        <meta name='twitter:image' content='/static/imgs/meta-image.jpg' key='twitter-image' />
        <link rel='canonical' href={`https://${host || 'kover.ru'}/`} key='canonical' />
      </Head>

      <div className={classnames('main-page', styles.main)}>

        <div className={styles.mainPart}>
          {seoText && (
            <section className={classnames(styles.mainSection)}>
              <Typography variant='h1'>{seoText?.name}</Typography>
              <Markdown source={seoText?.previewText} />
              <Markdown source={seoText?.detailText} style={{ height: readMore ? 'auto' : 0, overflow: 'hidden' }} />
              <div className={styles.readMoreLink} onClick={() => setReadMore(!readMore)}>
                {!readMore ? 'Читать дальше…' : 'Скрыть'}
              </div>
            </section>
          )}
        </div>

        <div className={styles.mainPart}>
          <section className={classnames(styles.mainSection, styles.mainMobileFeatures)}>
            <Typography className={styles.mainMobileFeaturesTitle} variant='h2' component='p'>
              Мягкий шаг к счастью
            </Typography>
            <MobileFeaturesBanner className={styles.mainMobileFeaturesBanner} info={info} />
            {!!filtersBlock?.length && (
              <div className={styles.quickLinks}>
                <div className={styles.quickLinksItems}>
                  {filtersBlock.map((item) => {
                    const { id, name, link } = item;

                    return (
                      <a key={`quick-link-${id}`} className={styles.quickLinksItem} href={link}
                         onClick={handleQuickLinkClick}>
                        {name}
                      </a>
                    );
                  })}
                </div>
              </div>
            )}
          </section>

          {slider && services && (
            <section className={classnames(styles.mainSection, styles.mainFeatures)}>
              <div className={classnames(styles.mainFeaturesSlider, 'banner-items')} data-width={slideWidth} style={{ minHeight: slideHeight }}>
                {slider.length === 1 ? renderSlide(first(slider)) : (
                  <Carousel
                    name='main-slider'
                    isSingleSlide
                    isDefaultBottomRightControlsEnabled
                    slideWidth={slideWidth}
                  >
                    {slider?.map(renderSlide)}
                  </Carousel>
                )}
              </div>

              <div className={styles.mainFeaturesDetails}>
                <Typography variant='h2' component='div' bold className={styles.mainFeaturesTitle}>Услуги для вас</Typography>
                <div className={styles.mainFeaturesItems}>
                  {services
                    ?.filter((item, k) => {
                      if (windowWidth < 768) {
                        if (isServicesExpanded) {
                          return true;
                        } else {
                          if (services?.length > 4 && k > 3) {
                            return false;
                          }
                          return true;
                        }
                      }
                      return true;
                    })
                    ?.map((service) => {
                      const serviceName = service?.name;
                      const serviceLink = service?.link;
                      const serviceIcon = service?.icon;
                      const serviceDescription = service?.description;

                      const content = (
                        <>
                          <div className={styles.mainFeaturesItemMedia}>
                            <img className={styles.mainFeaturesItemIcon} src={`${NEXT_PUBLIC_CDN_ROOT}${serviceIcon}`} alt={serviceName} loading='lazy' />
                          </div>
                          <div className={styles.mainFeaturesItemHeader}>
                            <Markdown className={styles.mainFeaturesItemTitle} source={serviceName} isExtendedMode />
                            {!!serviceDescription &&
                              <Markdown className={styles.mainFeaturesItemDescription} source={serviceDescription} isExtendedMode />}
                          </div>
                          <div className={styles.mainFeaturesItemActionIcon}>
                            <img src={arrowRightIcon} alt='' loading='lazy' />
                          </div>
                        </>
                      );

                      if (serviceLink) {
                        return (
                          <Link
                            href={serviceLink || ''}
                            key={`service-${serviceLink}`}
                            className={styles.mainFeaturesItem}
                            target='_blank'
                            rel='noopener noreferrer'
                            onClick={e => handlerEvent({ action: 'Услуги', label: serviceName })}
                          >
                            {content}
                          </Link>
                        );
                      }
                      return (
                        <div className={styles.mainFeaturesItem} key={`service-${serviceLink}`} onClick={e => handlerEvent({ action: 'Услуги', label: serviceName })}>
                          {content}
                        </div>
                      );
                    })
                  }

                  {services?.length > 4 && windowWidth < 768 && (
                    <button className={styles.mainFeaturesExpand} onClick={handleServicesExpand}>
                      {!isServicesExpanded ? 'Показать все услуги' : 'Скрыть'}
                      <div className={classnames(styles.mainFeaturesExpandIcon, { [styles.rotated]: isServicesExpanded })}>
                        <img src={arrowDownIcon} />
                      </div>
                    </button>
                  )}
                </div>
              </div>
            </section>
          )}

          {bannersKover && (
            <section className={classnames(styles.mainSection, styles.mainCategories, styles.mainCarpetCategories)}>
              {windowWidth >= 768 && (
                (<Link
                  href='/catalog'
                  prefetch={false}
                  className={styles.mainSquareAction}
                  style={carpetSquareStyle}
                  onClick={() => handlerEvent({ action: 'Каталог ковров', label: 'Каталог ковров' })}>

                  <div className={styles.mainSquareActionContent}>
                    <Typography className={styles.mainSquareActionTitle} variant='h3' component='span'>
                      Каталог ковров
                    </Typography>
                    <Typography className={styles.mainSquareActionButton} variant='button' bold>
                      Смотреть
                      <SvgIcon svg={linkArrowIcon} />
                    </Typography>
                  </div>

                </Link>)
              )}

              {windowWidth >= 768 && (
                <div className={styles.mainCategoriesItems}>
                  {bannersKover?.slice(0, windowWidth < 1024 ? 2 : bannersKover.length).map((banner) => {
                    const id = banner?.id;
                    const link = banner?.link;
                    const name = banner?.name;
                    const picture = banner?.picture;

                    return (
                      <div key={`banner-${id}`} className={classnames(styles.mainCategoriesItem, { [styles.sale]: name === 'Sale' || name === 'Распродажа' })} onClick={e => handlerEvent({ action: 'Каталог ковров', label: name })}>
                        <Link href={link || ''}>

                          <div className={styles.mainCategoriesItemHeader}>
                            <Picture
                              className={styles.mainCategoriesItemPicture}
                              imgClassName={styles.mainCategoriesItemImage}
                              picture={picture}
                              imgAttributes={{ loading: 'lazy' }}
                            />
                            <Markdown className={styles.mainCategoriesItemTitle} source={name} />
                            <SvgIcon className={styles.mainCategoriesItemIcon} svg={arrowLinkIcon} />
                          </div>

                        </Link>
                      </div>
                    );
                  })}
                </div>
              )}
              {windowWidth < 1024 && (
                <div className={styles.mainCategoriesItems}>
                  {bannersKover?.slice(windowWidth >= 768 ? 2 : 0, bannersKover.length).map((banner) => {
                    const id = banner?.id;
                    const link = banner?.link;
                    const name = banner?.name;
                    const picture = banner?.picture;

                    return (
                      <div key={`banner-${id}`} className={classnames(styles.mainCategoriesItem, { [styles.sale]: name === 'Sale' || name === 'Распродажа' })} onClick={e => handlerEvent({ action: 'Каталог ковров', label: name })}>
                        <Link href={link || ''} prefetch={false}>

                          <div className={styles.mainCategoriesItemHeader}>
                            <Picture
                              className={classnames(styles.mainCategoriesItemPicture)}
                              imgClassName={styles.mainCategoriesItemImage}
                              picture={picture}
                              imgAttributes={{ loading: 'lazy' }}
                            />
                            <Markdown className={styles.mainCategoriesItemTitle} source={name} />
                            <img className={styles.mainCategoriesItemIcon} src={arrowLinkIcon} alt={name} loading='lazy' />
                          </div>

                        </Link>
                      </div>
                    );
                  })}
                </div>
              )}
            </section>
          )}

          <section className={classnames(styles.mainSection)}>
            <div className={classnames(styles.mainFeatureBanners)}>
              <Link
                href='/blog/kak-vybrat-kover/kak-podobrat-idealnyy-kovyer-5-glavnykh-sovetov'
                className={classnames(styles.mainFeatureBannersItem, styles.mainFeatureBannersItemHowSelect)}
                onClick={() => makeHandleFeatureBannerClick('Узнайте всего за 3 минуты')}>

                <picture>
                  <source srcSet='/static/imgs/main/how-select-carpet.webp' type='image/webp' />
                  <img src='/static/imgs/main/how-select-carpet.jpg' alt='-' loading='lazy' />
                </picture>
                <div className={styles.mainFeatureBannersItemContent}>
                  <Typography className={styles.mainFeatureBannersItemTitle} variant='h4' component='span'>
                    Узнайте всего за
                    <br />
                    3 минуты&nbsp;&nbsp;›
                  </Typography>
                  <Typography className={styles.mainFeatureBannersItemDescription} variant='body2'>
                    Видео инструкция:
                    <br />
                    как выбрать ковер от kover.ru
                  </Typography>
                </div>

              </Link>
              <Link
                href='/demonstration'
                className={classnames(styles.mainFeatureBannersItem, styles.mainFeatureBannersItemFreeFit)}
                onClick={() => makeHandleFeatureBannerClick('Бесплатно привезем ковры для демонстрации у вас дома')}
              >
                <picture>
                  <source srcSet='/static/imgs/main/free-carpet-fit.webp' type='image/webp' />
                  <img src='/static/imgs/main/free-carpet-fit.jpg' alt='-' loading='lazy' />
                </picture>
                <div className={styles.mainFeatureBannersItemContent}>
                  <Typography className={styles.mainFeatureBannersItemTitle} variant='h4' component='span'>
                    {!info?.isFreeShippingDisabled ? 'Бесплатно привезем' : 'Привезем'}
                    {' '}
                    ковры для демонстрации у вас дома&nbsp;&nbsp;›
                  </Typography>
                  <Button className={styles.mainFeatureBannersItemButton}>
                    Подробнее
                  </Button>
                </div>

              </Link>
            </div>
          </section>

          {bannersLiberty && (
            <section className={classnames(styles.mainSection, styles.mainCategories, styles.mainLibertyCategories)}>
              {windowWidth < 1280 && windowWidth >= 1024 && renderedLibertySquareAction}
              <div className={styles.mainCategoriesBigItems}>
                {windowWidth < 1024 && renderedLibertySquareAction}
                {bannersLiberty?.map((banner) => {
                  const id = banner?.id;
                  const link = banner?.link;
                  const name = banner?.name;
                  const picture = banner?.picture;

                  return (
                    <div key={`banner-${id}`} className={styles.mainCategoriesBigItem} onClick={e => handlerEvent({ action: 'Каталог мебели', label: name })}>
                      <Link href={link} className={styles.mainCategoriesBigItemHeader}>

                        <Picture
                          className={styles.mainCategoriesBigItemPicture}
                          imgClassName={styles.mainCategoriesBigItemImage}
                          picture={picture}
                          imgAttributes={{ loading: 'lazy' }}
                        />
                        <Typography className={styles.mainCategoriesBigItemTitle} variant='h5'
                                    component='span'>{name}</Typography>

                      </Link>
                    </div>
                  );
                })}
                {windowWidth >= 1280 && renderedLibertySquareAction}
              </div>
            </section>
          )}

          <MainFilters filters={filters} handlerEvent={(event) => console.log(event)} />

          {reviews && (
            <section className={classnames(styles.mainSection, styles.mainReviews)}>
              <Carousel className={styles.mainReviewsSlider} slideWidth={Math.floor((width) + 12)} isSingleSlide isArrowButtonShadowEnabled>
                {reviews?.map((review, k) => (
                  <MiniReview review={review} key={`review-${k}`} />
                ))}
              </Carousel>

              <div className={styles.mainReviewsActions}>
                <Button medium href='/reviews'>
                  Читать все отзывы
                </Button>
              </div>
            </section>
          )}

          {shops && (
            <section className={classnames(styles.mainSection, styles.mainShops)}>
              <div className={styles.mainShopsHeader}>
                <Typography component='div' variant='h1'>Ждём вас в магазинах наших партнеров!</Typography>
                <Button
                  className={styles.mainShopsButton}
                  href='/adresa-magazinov'
                  onClick={() => handlerEvent({ action: 'Магазины', label: 'Все магазины' })}
                >
                  Все магазины в вашем городе
                  <SvgIcon svg={linkArrowIcon} />
                </Button>
              </div>
              <Carousel
                name='our-shops'
                className={styles.mainShopsSlider}
                slideWidth={windowWidth < 768 ? width : Math.floor((width - (width < 800 ? 32 : 64)) / 2 - 24)}
                isSingleSlide={windowWidth < 768}
                isArrowButtonShadowEnabled
              >
                {shops?.map((shop) => {
                  const shopId = shop?.id;
                  const shopCode = shop?.code;
                  const shopName = shop?.name;
                  const shopAddress = shop?.address;
                  const shopPhone = shop?.phone;
                  const shopWorkingTime = shop?.workingTime;
                  const shopPicture = shop?.picture;

                  return (
                    <div className={styles.mainShop} key={`shop-${shopId}`}>
                      <Link
                        href={`/adresa-magazinov/${shopCode}`}
                        onClick={() => {
                          handlerEvent({ action: 'Магазины', label: shopName?.replace("'", '').replace('\'', '') });
                        }}
                      >

                        <Picture
                          imgClassName={styles.mainShopImage}
                          picture={shopPicture}
                          style={{ display: 'block' }}
                          imgStyle={{
                            display: 'block',
                            height: windowWidth < 768 ? Math.floor(width * 3 / 4) : Math.floor((((width - (width < 800 ? 32 : 64)) / 2) - 24) * 3 / 4)
                          }}
                          imgAttributes={{ loading: 'lazy' }}
                        />

                      </Link>

                      <Link
                        href={`/adresa-magazinov/${shopCode}`}
                        onClick={() => {
                          handlerEvent({ action: 'Магазины', label: shopName?.replace("'", '').replace('\'', '') });
                        }}>

                        <Typography className={styles.mainShopTitle} variant='h3' component='div'>{shopName}</Typography>
                      </Link>
                      <div className={styles.mainShopInfo}>
                        <div className={styles.mainShopInfoItem}>
                          <SvgIcon className={styles.mainShopInfoItemIcon} svg={phoneIcon} />
                          <Typography className={styles.mainShopInfoItemTitle} variant='body1'>
                            {shopPhone.join(', ')}
                            {shopPhone?.length === 2 ? ' (Напишите нам на WhatsApp, Telegram, Viber)' : ''}
                          </Typography>
                        </div>
                        <div className={styles.mainShopInfoItem}>
                          <SvgIcon className={styles.mainShopInfoItemIcon} svg={clockIcon} />
                          <Typography className={styles.mainShopInfoItemTitle}
                                      variant='body1'>{shopWorkingTime}</Typography>
                        </div>
                        <div className={styles.mainShopInfoItem}>
                          <SvgIcon className={styles.mainShopInfoItemIcon} svg={pointIcon} />
                          <Typography className={styles.mainShopInfoItemTitle}
                                      variant='body1'>{shopAddress}</Typography>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
              <Button className={styles.mainShopsButtonSecond} href='/adresa-magazinov'>
                Все магазины в вашем городе
                <SvgIcon svg={linkArrowIcon} />
              </Button>
            </section>
          )}

          {projects && (
            <section className={classnames(styles.mainSection, styles.mainDesignCases)}>
              <div className={styles.mainDesignCasesContent}>
                <div className={styles.mainDesignCasesHeader}>
                  <Typography component='div' variant='h1'>Совместные проекты с дизайнерами</Typography>
                </div>
                <div className={styles.mainDesignCasesItems}>
                  {projects?.map((project, i) => {
                    const projectName = project?.name;
                    const projectPictures = project?.pictures;
                    const projectLink = project?.link;
                    const projectAuthors = project?.authors;

                    const handleDesignerSliderClick = () => {
                      window.dataLayer?.push({
                        event: 'promoClick',
                        eventCategory: 'Главная',
                        eventAction: 'Проекты с дизайнерами',
                        eventLabel: projectName, // текст баннера
                        ecommerce: {
                          promoClick: {
                            promotions: [{
                              name: projectName, // н-р Ковры в загородный дом
                              position: 'Главная. Проекты с дизайнерами'
                            }]
                          }
                        }
                      });
                    };

                    return (
                      <div className={styles.mainDesignCase} key={`design-case-${i}`}>
                        <Carousel name='design-cases' className={styles.mainDesignCaseSlider} slideWidth={windowWidth < 1024 ? width - 24 : Math.floor((width / 2) - 24)} isSingleSlide={windowWidth < 1024}>
                          {projectPictures?.map((projectPicture, k) => (
                            <Picture
                              key={`project-picture-${k}`}
                              imgClassName={styles.mainDesignCaseImage}
                              picture={projectPicture}
                              style={{ display: 'block' }}
                              imgStyle={{
                                display: 'block',
                                height: windowWidth < 1024 ? Math.floor((width - 24) * 3 / 4) : Math.floor(Math.floor((width / 2) + 12) * 3 / 4)
                              }}
                              imgAttributes={{ loading: 'lazy' }}
                            />
                          ))}
                        </Carousel>
                        <Typography className={styles.mainDesignCaseDescription} variant='body1'>{projectAuthors}</Typography>
                        <Link
                          href={projectLink || ''}
                          className={styles.mainDesignCaseLink}
                          onClick={handleDesignerSliderClick}
                        >
                          <Typography className={styles.mainDesignCaseTitle} variant='h4' component='div'>{projectName}</Typography>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          )}

          {designersInvite && (
            <section className={classnames(styles.mainSection, styles.mainDesigners)}>
              <div className={styles.mainDesignersHeader}>
                <Typography component='div' variant='h1'>{designersInvite?.name}</Typography>
              </div>
              <div className={styles.mainDesignersContent}>
                <div className={styles.mainDesignersMedia}>
                  <Picture
                    imgClassName={styles.mainDesignersImage}
                    picture={designersInvite?.picture}
                    style={{ display: 'block' }}
                    imgStyle={{ display: 'block' }}
                    imgAttributes={{ loading: 'lazy' }}
                  />
                </div>
                <div className={styles.mainDesignersInfo}>
                  <div className={styles.mainDesignersInfoColumn}>
                    <Markdown
                      className={styles.mainDesignersContentTitle}
                      source={designersInvite?.headers?.left}
                    />
                    <div>
                      <ul className={styles.mainDesignersItems}>
                        {designersInvite?.advantages?.map((designersInviteAdvantage, k) => (
                          <li key={`designers-adv-${k}`} className={styles.mainDesignersItem}>
                            <div className={styles.mainDesignersItemMedia}>
                              <SvgIcon svg={checkArrowIcon} />
                            </div>
                            <Typography className={styles.mainDesignersItemTitle} variant='body1'>
                              {designersInviteAdvantage}
                            </Typography>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className={styles.mainDesignersInfoColumn}>
                    {!!designersInvite?.headers?.right && (
                      <Markdown
                        className={styles.mainDesignersContentTitle}
                        source={designersInvite?.headers?.right}
                      />
                    )}
                    <div>
                      {!!designersInvite?.approvals && (
                        <ul className={styles.mainDesignersItems}>
                          {designersInvite?.approvals?.map((designersInviteAdvantage, k) => (
                            <li key={`designers-app-${k}`} className={styles.mainDesignersItem}>
                              <div className={styles.mainDesignersItemMedia}>
                                <SvgIcon svg={checkArrowIcon} />
                              </div>
                              <Typography className={styles.mainDesignersItemTitle} variant='body1'>
                                {designersInviteAdvantage}
                              </Typography>
                            </li>
                          ))}
                        </ul>
                      )}
                      <Button href='/designer' onClick={() => handlerEvent({ action: 'Дизайнерам', label: 'Стать партнером' })}>Стать партнером</Button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {advantages && (
            <section className={classnames(styles.mainSection)}>
              <ul className={classnames(styles.mainMeasures)}>
                <li className={classnames(styles.mainMeasure, styles.mainMeasureGeneral)}>
                  <Typography className={styles.mainMeasureDescription} variant='body1'>
                    По решению “Национальной Ассоциации Ковроткачества и продавцов ковровых изделий” интернет-магазин
                    kover.ru
                    {' '}
                    <a href='http://kovryrossii.ru/certificate' target='_blank' rel='nofollow noreferrer'>признан лучшим
                      ковровым сайтом в России</a>
                    , и мы гордимся признанием наших заслуг
                  </Typography>
                  <img src={trophyIcon} alt='Лучший интернет-магазин ковров в России' loading='lazy' />
                </li>
                {advantages?.map((advantage, k) => {
                  const { label, unit, text } = advantage;

                  return (
                    <li key={`all-adv-${k}`} className={styles.mainMeasure}>
                      <div className={styles.mainMeasureContent}>
                        <div
                          className={classnames(styles.mainMeasureTitle, { [styles.mainMeasureTitleRow]: unit === '%' })}>
                          <strong>{label}</strong>
                          {unit}
                        </div>
                        <Typography className={styles.mainMeasureDescription} variant='body1'>
                          {text}
                        </Typography>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </section>
          )}

          <section className={classnames(styles.mainSection, styles.mainDesignCollections)}>
            <div className={styles.mainDesignCollectionsContent}>
              <div className={styles.mainDesignCollectionsHeader}>
                <Typography component='div' variant='h1'>Дизайнерские подборки</Typography>
              </div>

              <CollectionList />
            </div>
          </section>

          <section className={classnames(styles.mainSection, styles.mainWholesale)}>
            <div className={styles.mainWholesaleContent}>
              <Typography className={styles.mainWholesaleTitle} variant='h1' component='div'>
                Оптовый заказ?
                <br />
                Специальные условия!
              </Typography>

              <Button
                className={styles.mainWholesaleButton}
                transparentWhite
                href='/wholesale'
                onClick={() => handlerEvent({ action: 'Оптовым покупателям', label: 'Оптовым покупателям' })}
              >
                Оптовым покупателям
                <SvgIcon svg={linkArrowIcon} />
              </Button>
            </div>
          </section>

          {bestArticles && !!bestArticles.length && (
            <section className={classnames(styles.mainSection, styles.mainBlog)}>
              <div className={styles.mainBlogHeader}>
                <Typography component='div' variant='h1'>Лучшие статьи</Typography>
              </div>

              <div className={styles.mainBlogContent}>
                <Link
                  href={bestArticlesFirst?.link || ''}
                  className={styles.mainBlogItemBig}
                  onClick={() => handlerEvent({
                    action: 'Лучшие статьи',
                    label: bestArticlesFirst?.name?.replace("'", '').replace('\'', '')
                  })}>

                  <Picture
                    className={styles.mainBlogItemBigPicture}
                    imgClassName={styles.mainBlogItemBigImg}
                    picture={bestArticlesFirst?.picture}
                    style={{ display: 'block' }}
                    imgStyle={{ display: 'block' }}
                    imgAttributes={{ loading: 'lazy' }}
                  />
                  <Link
                    href={bestArticlesFirst?.labelLink || ''}
                    className={classnames(styles.mainBlogItemCategory, styles.mainBlogItemBigCategory)}>

                    {bestArticlesFirst?.labelText}

                  </Link>
                  <Typography component='div' variant='h1'
                              className={classnames(styles.mainBlogItemTitle, styles.mainBlogItemBigTitle)}>
                    {bestArticlesFirst?.name}

                    <Button transparentWhite className={styles.mainBlogItemBigReadMore}>
                      Читать дальше
                      <SvgIcon svg={linkArrowIcon} />
                    </Button>
                  </Typography>

                </Link>
                <ul className={styles.mainBlogItems}>
                  {bestArticlesLast?.map((article) => {
                    const {
                      name,
                      previewPicture,
                      link,
                      labelText,
                      labelLink
                    } = article;

                    return (
                      <li key={`article-${link}`} className={styles.mainBlogItem}>
                        <Link
                          href={link || ''}
                          className={styles.mainBlogItemLink}
                          onClick={() => handlerEvent({
                            action: 'Лучшие статьи',
                            label: name?.replace("'", '').replace('\'', '')
                          })}>

                          <Picture
                            className={styles.mainBlogItemLinkPicture}
                            imgClassName={styles.mainBlogItemLinkImg}
                            picture={article?.picture}
                            style={{ display: 'block' }}
                            imgStyle={{ display: 'block' }}
                            imgAttributes={{ loading: 'lazy' }}
                          />
                          <Link
                            href={labelLink || ''}
                            className={classnames(styles.mainBlogItemCategory)}>

                            {labelText}

                          </Link>
                          <Typography component='div' variant='h6' className={styles.mainBlogItemTitle}>
                            {name}
                          </Typography>

                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </section>
          )}
        </div>
      </div>
    </>
  );
};

export default Main;
