/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import classnames from 'classnames';
import Link from 'next/link';

import { MainFilter, useAppClient } from '@/core';
import SvgIcon from 'components/SvgIcon';
import Typography from 'components/Typography';

import filterArrowCollapseIcon from '../assets/filter-arrow-collapse.svg';
import filterArrowExpandIcon from '../assets/filter-arrow-expand.svg';

import {
  ColorFilterTabPanel2,
  PriceFilterTabPanel,
  ShapeFilterTabPanel,
  SizeFilterTabPanel
} from './FilterTabPanel';
import useMainFilters from './useMainFilters';

import mainStyles from '../Main.module.css';
import styles from './Filters.module.css';

import allFilterButtonIcon from '/assets/imgs/main/all-filter.svg';
import colorFilterButtonIcon from '/assets/imgs/main/color-filter.svg';
import priceFilterButtonIcon from '/assets/imgs/main/price-filter.svg';
import shapeFilterButtonIcon from '/assets/imgs/main/shape-filter.svg';
import sizeFilterButtonIcon from '/assets/imgs/main/size-filter.svg';

interface MainFiltersProps {
  filters: MainFilter[];
  handlerEvent: (event: any) => void;
}

const MainFiltersContent = ({ filters, handlerEvent }: MainFiltersProps) => {
  const [selectedTab, setSelectedTab] = useState('color');
  const isSelectedTab = (value) => selectedTab === value;

  const { isDesktop } = useAppClient();

  const { colorFilterItems, sizeFilterItems, priceFilterItems, shapeFilterItems } = useMainFilters(filters);

  const renderCollapseArrowIcon = (tab) => (
    <SvgIcon
      className={styles.tabsHeaderButtonContentArrow}
      svg={isSelectedTab(tab) ? filterArrowCollapseIcon : filterArrowExpandIcon}
    />
  );

  return (
    <div className={styles.content}>
      <div className={styles.tabsHeader}>
        <button
          type='button'
          className={classnames(styles.tabsHeaderButton, styles.tabColor, {
            [styles.selected]: isSelectedTab('color'),
          })}
          onClick={(e) => {
            const target = e.target as HTMLButtonElement;
            setSelectedTab('color');
            handlerEvent({ action: 'Фильтры', label: target.innerText });
          }}
        >
          <div className={styles.tabsHeaderButtonContent}>
            <SvgIcon className={styles.icon} svg={colorFilterButtonIcon} />
            По цвету
            {renderCollapseArrowIcon('color')}
          </div>
        </button>
        <ColorFilterTabPanel2
          selected={isSelectedTab('color')}
          items={colorFilterItems}
          className={classnames({ [styles.selected]: isSelectedTab('color') }, styles.tabPanel, styles.tabPanelFirst)}
        />
        <button
          type='button'
          className={classnames(styles.tabsHeaderButton, styles.tabShape, {
            [styles.selected]: isSelectedTab('shape'),
          })}
          onClick={(e) => {
            const target = e.target as HTMLButtonElement;

            setSelectedTab('shape');
            handlerEvent({ action: 'Фильтры', label: target.innerText });
          }}
        >
          <div className={styles.tabsHeaderButtonContent}>
            <SvgIcon className={styles.icon} svg={shapeFilterButtonIcon} />
            По форме
            {renderCollapseArrowIcon('shape')}
          </div>
        </button>
        <ShapeFilterTabPanel
          className={classnames(styles.tabPanel, styles.tabPanelFirst)}
          items={shapeFilterItems}
          selected={isSelectedTab('shape')}
        />
        <button
          type='button'
          className={classnames(styles.tabsHeaderButton, styles.tabSize, {
            [styles.selected]: isSelectedTab('size'),
          })}
          onClick={(e) => {
            const target = e.target as HTMLButtonElement;

            setSelectedTab('size');
            handlerEvent({ action: 'Фильтры', label: target.innerText });
          }}
        >
          <div className={styles.tabsHeaderButtonContent}>
            <SvgIcon className={styles.icon} svg={sizeFilterButtonIcon} />
            По размеру
            {renderCollapseArrowIcon('size')}
          </div>
        </button>
        <SizeFilterTabPanel
          className={classnames(styles.tabPanel, {
            [styles.tabPanelSecond]: !isDesktop,
            [styles.tabPanelFirst]: isDesktop,
          })}
          items={sizeFilterItems}
          selected={isSelectedTab('size')}
        />

        <button
          type='button'
          className={classnames(styles.tabsHeaderButton, styles.tabPrice, {
            [styles.selected]: isSelectedTab('price'),
          })}
          onClick={(e) => {
            const target = e.target as HTMLButtonElement;

            setSelectedTab('price');
            handlerEvent({ action: 'Фильтры', label: target.innerText });
          }}
        >
          <div className={styles.tabsHeaderButtonContent}>
            <SvgIcon className={styles.icon} svg={priceFilterButtonIcon} />
            По цене
            {renderCollapseArrowIcon('price')}
          </div>
        </button>
        <PriceFilterTabPanel
          className={classnames(styles.tabPanel, styles.tabPanelSecond)}
          items={priceFilterItems}
          selected={isSelectedTab('price')}
        />

        <Link
          href='/catalog'
          className={classnames(styles.tabsHeaderButton, styles.tabLink, { [styles.selected]: false })}
          onClick={(e) => {
            const target = e.target as HTMLButtonElement;

            handlerEvent({ action: 'Фильтры', label: target.innerText });
          }}
        >
          <div className={styles.tabsHeaderButtonContent}>
            <SvgIcon className={styles.icon} svg={allFilterButtonIcon} />
            Посмотреть все фильтры
            <SvgIcon className={styles.tabsHeaderButtonContentArrow} svg={filterArrowExpandIcon} />
          </div>
        </Link>
      </div>
    </div>
  );
};

const MainFilters = ({ filters, handlerEvent }: MainFiltersProps) => (
  <section className={classnames(mainStyles.mainSection, styles.root)}>
    <div className={styles.header}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-expect-error */}
      <Typography component='div' variant='h1'>
        Выбирайте ковры в нашем интернет-магазине легко!
      </Typography>
    </div>

    <MainFiltersContent filters={filters} handlerEvent={handlerEvent} />
  </section>
);

export default MainFilters;
